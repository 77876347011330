/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
import axios from 'axios'
import { getURLParam } from '@helpers/general'
import { getFromBrowserStorage, removeLocalStorage, saveLocalStorage } from '@helpers/storage'
import { sentryLogger } from '@helpers/sentry-logger'

const COUPON_PREFIXES = ['OFF', 'PER', 'DIS']

/**
 * check if we have a new coupon available in the URL; if so, save it to localStorage
 * otherwise, check to see if a saved coupon in localStorage has expired
 *
 * @returns {Promise<void>}
 */
const checkCoupon = async () => {
  const myUrlCoupon = hasUrlCoupon()
  const mySavedCoupon = hasSavedCoupon()
  if (myUrlCoupon) {
    const couponId = getUrlCouponId()
    const couponType = getUrlCouponType()
    if (!Object.is(couponId, null)) {
      const response = await validateCoupon(couponId, couponType)
      if (response?.isValid || response?.valid) {
        saveLocalStorage('coupon', JSON.stringify(response)) // id, expiration, value
        saveLocalStorage('couponType', couponType)
      }
    }
  } else if (mySavedCoupon) {
    const savedCouponObj = getSavedCouponObj()
    let remove = false
    const { expiration, expirationDate } = savedCouponObj
    const couponExpDate = new Date(expiration || expirationDate)
    if (couponExpDate.valueOf() < new Date()) remove = true
    if (remove) {
      removeLocalStorage('coupon')
      removeLocalStorage('couponType ')
    }
  }
}

export { checkCoupon }

/**
 * validate the coupon with the backend microservice
 *
 * @param couponId
 * @param couponType
 * @returns {Promise<AxiosResponse<any>>} Thank You: [id {str}, expiration {date}, valid {bool}]
 * @returns {Promise<AxiosResponse<any>>} Customer Care: [code {str}, expirationDate {date}, isValid {bool}]
 */
export const validateCoupon = (couponId, couponType) => {
  let couponUrlHost = process.env.GATSBY_THANKYOU_SERVICE_URL
  const customerCareCoupon = COUPON_PREFIXES.some(prefix => prefix === couponType)
  if (customerCareCoupon) {
    couponUrlHost = process.env.GATSBY_CUSTOMER_CARE_COUPON_SERVICE_URL
  }
  return axios
    .get(`${couponUrlHost}/v1/coupons/${couponId}`, {
      headers: {
        'x-api-key': process.env.GATSBY_CUSTOMER_CARE_COUPON_SERVICE_API_KEY,
      },
    })
    .then(res => {
      if ((customerCareCoupon && !res?.data?.isValid) || (!customerCareCoupon && !res?.data?.valid)) {
        throw new Error('Coupon not found or invalid')
      }
      return res.data
    })
    .catch(error => {
      const res = {
        isValid: false,
        valid: false,
        message: error?.message || error?.response?.data?.message,
        status: error?.response?.status,
        error,
      }
      sentryLogger({
        captureException: {
          href: window?.location?.href,
          couponId,
          page: 'sales store coupons',
          requestUrl: `${ customerCareCoupon 
            ? process.env.GATSBY_CUSTOMER_CARE_COUPON_SERVICE_URL 
            : process.env.GATSBY_THANKYOU_SERVICE_URL
          }/v1/coupons/${couponId}`,
          status: res.status,
          message: res.message,
          error,
        },
      })
      return res
    })
}

/**
 * does the URL contains a coupon query param?
 *
 * @returns {boolean}
 */
export const hasUrlCoupon = () => !Object.is(getUrlCouponId(), null)

/**
 * retrieve the coupon id in the URL
 *
 * @returns {string}
 */
export const getUrlCouponId = () => getURLParam('cid')

/**
 * retrieve the coupon type in the URL
 *
 * @returns {string}
 */
export const getUrlCouponType = () => {
  const couponId = getUrlCouponId()
  if (!Object.is(couponId, null)) {
    return couponId.substring(0, 3)
  }
  return ''
}

/**
 * does the user have a coupon saved to localStorage?
 *
 * @returns {boolean}
 */
export const hasSavedCoupon = () => {
  const savedCouponObj = getSavedCouponObj()
  return !Object.is(Object.keys(savedCouponObj).length, 0)
}

/**
 * retrieve the coupon object saved in localStorage
 *
 * @returns {object} [id, expiration, valid]
 */
export const getSavedCouponObj = () => getFromBrowserStorage('local', 'coupon')

/**
 * retrieve the coupon type saved in localStorage
 *
 * @returns {string}
 */
export const getSavedCouponType = () => getFromBrowserStorage('local', 'couponType')
