export const attemptScrollToTop = () => {
  try {
    window.scroll({ top: 0, behavior: 'smooth', block: 'start' })
  } catch {
    // do nothing
  }
}

export const getURLParam = (key, windowData) => {
  let paramValue = null
  let paramString
  if (windowData && windowData.location && windowData.location.search) {
    paramString = windowData.location.search
  } else if (typeof window !== 'undefined' && window.location && window.location.search) {
    paramString = window.location.search
  }
  if (paramString) {
    const params = paramString.replace('?', '').split('&')
    const paramIndex = params.findIndex(param => param.includes(key))
    if (paramIndex >= 0) {
      // eslint-disable-next-line prefer-destructuring
      paramValue = params[paramIndex].split('=')[1]
    }
  }
  return paramValue
}

export const isValidUrl = url => {
  try {
    return new URL(url)
  } catch {
    return false
  }
}

export class HttpError extends Error {
  /**
   * @param {string} message - Useful error message
   * @param {Response} response - Request response
   * @param {string} method - Request method
   * @returns {Promise<never>}
   */
  static of(message, response, method, data) {
    return Promise.reject(new HttpError(message, response, method, data))
  }

  /**
   * @param {any} value
   * @returns {boolean}
   */
  static isHttpError(value) {
    if (value && value instanceof HttpError) {
      return true
    }
    return false
  }

  /**
   * @param {string} message Useful error message
   * @param {Response} response - Request response
   * @param {string} method - Request method
   */
  constructor(message, response = null, method = 'GET', data = null) {
    super()
    this.name = 'HttpError'
    this.message = message
    this.endpoint = response ? response.url : null
    this.status = response ? response.status : null
    this.method = method
    this.data = data
    this.statusText = response ? response.statusText : null
  }

  toString() {
    return `
      [${this.name}]: ${this.message}
      - endpoint: ${this.endpoint}
      - status: ${this.status}
      - method: ${this.method}
      - statusText: ${this.statusText || null}
      - data: ${this.data}


      ${process.env.NODE_ENV === 'development' ? this.stack : ''}
    `
  }

  toJSON() {
    const json = {
      message: this.message,
      endpoint: this.endpoint,
      status: this.status,
      method: this.method,
      statusText: this.statusText,
      data: this.data,
    }
    if (process.env.NODE_ENV === 'development') {
      json.stack = this.stack
    }
    return json
  }
}

/**
 * Http request helper
 * @param {string} path endpoint url pathname
 * @param {object} body request body
 * @param {string} method request method
 * @param {string} token access token
 * @param {string} errorMessage deafult error message
 * @param {string} baseUrl
 * @param {string} authHeaderName Authentication header
 * @returns {Promise<any>} Http response or error
 */
export const request = async (
  path,
  body,
  method = 'GET',
  token = null,
  errorMessage = 'Http request error',
  baseUrl = '',
  authHeaderName = 'X-Token',
) => {
  const url = `${baseUrl}${path}`

  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(token && authHeaderName ? { [authHeaderName]: token } : {}),
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  })

  const data = await response.json()

  if (!response.ok) {
    throw HttpError.of(errorMessage, response, method, data)
  }

  return data
}
