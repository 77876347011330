import * as Sentry from '@sentry/browser'

export const setExtra = 'setExtra'

export const levels = {
  debug: 'debug',
  error: 'error',
  fatal: 'fatal',
  info: 'info',
  warning: 'warning',
}

export const cleanOrderObject = order => ({
  ...order,
  billingAddress: null,
  shippingAddress: null,
  contact: null,
  payer: null,
})

export const sentryMessages = {
  updateAddressFailure: 'Update Address Failed',
  updateDeliveryFailure: 'Update Delivery Failed',
  updatePaymentFailure: 'Update Payment Failed',
  deliveryCalendarFailure: 'Delivery Calendar Failed',
  paymentDenial: `Checkout - Review Section Place Order Denied`,
  orderFailure: `Checkout - Review Section Place Order Failure`,
  paymentUpdateFailure: 'Checkout - Payment Update Failure',
  QasFailure: `Checkout - QAS Failure`,
  digitalBuyFailure: 'Checkout - Digital Buy Failure',
  QasNetworkTimeout: `Checkout - QAS Network Timeout`,
  QasNetworkRequestMade: `Checkout - QAS Network Request Made`,
}
/*
Usage Examples :

init: {
    key1: value1,
    key2: value2,
},
configureScope: {
    type: 'setUser',
    variableExists: userIdVariable,
    id: userIdVariable,

    or

    type: 'setExtra',
    key1: value1,
    key2: value2,
    key3: value3,
},
captureMessage: {
    type: 'text',
    message: `this must be a string`,
    level: 'level of severity'
},

*/

const determineConfig = ({ type, ...args }) => {
  switch (type) {
    case 'setUser': {
      const { variableExists, ...rest } = args
      Sentry.configureScope(scope => variableExists && scope.setUser(rest))
      break
    }
    case setExtra:
      if (args) {
        Object.entries(args).forEach(([key, value]) =>
          Sentry.configureScope(scope => {
            if (key === 'level') scope.setLevel(value)
            else scope.setContext(`${key}`, value)
          }),
        )
      }
      break
    default:
      break
  }
}

export const sentryLogger = allMethodTypes => {
  // loop through passed methods and pass the relevant config-object to Sentry
  if (allMethodTypes) {
    const entries = Object.entries(allMethodTypes)
    entries.forEach(([methodType, data]) => {
      switch (methodType) {
        case 'init':
          Sentry.init(data)
          break
        case 'configureScope':
          determineConfig(data)
          break
        case 'captureMessage':
          Sentry.captureMessage(data.message, data.level)
          break
        case 'captureException':
          Sentry.captureException(data.message)
          break
        default:
          break
      }
    })
  }
}

export const logSentry = (order, text = '') => {
  sentryLogger({
    configureScope: {
      type: setExtra,
      level: levels.error,
      deliveryDate: order.deliveryDate,
      doorwayDelivery: order.doorwayDelivery,
      orderId: order.orderId,
      text,
    },
    captureMessage: {
      type: 'text',
      message: sentryMessages.updateDeliveryFailure,
      level: levels.error,
    },
  })
}

export default sentryLogger
